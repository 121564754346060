<template>
  <v-dialog v-model="dialog" data-cy="addCategoryDialog" max-width="600">
    <template #default>
      <v-card prepend-icon="$edit">
        <template #title>
          <div data-cy="dialogCategoryTitle">
            {{
              action == 'add' ? 'Ajouter une catégorie' : 'Editer une catégorie'
            }}
          </div>
        </template>

        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="state.title"
                label="Titre*"
                data-cy="categoryTitle"
                density="compact"
                required
                :error-messages="
                  v$.title.$errors.map((e) => e.$message as string)
                "
                @input="v$.title.$touch"
                @blur="v$.title.$touch"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <div
                class="sx__event"
                :style="
                  'background-color:' +
                  state.config?.lightColors.container +
                  ';border: 4px solid ' +
                  state.config?.lightColors.main +
                  '; color:' +
                  state.config?.lightColors.onContainer +
                  '; border-radius: 15px; padding: 10px;'
                "
              >
                <div class="sx__time-grid-event-inner">
                  <div class="sx__time-grid-event-title">
                    Titre de l'évènement
                  </div>
                  <div class="sx__time-grid-event-time">08:00 – 10:00</div>
                </div>
              </div>
            </v-col>
            <v-divider class="my-3"></v-divider> </v-row
          ><v-row no-gutters justify="center">
            <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
              <strong> Couleurs</strong>
            </v-col>
            <v-col cols="4" md="4" sm="4" class="d-flex justify-center">
              Barre vert.
            </v-col>
            <v-col cols="4" md="4" sm="4" class="d-flex justify-center">
              Police
            </v-col>
            <v-col cols="4" md="4" sm="4" class="d-flex justify-center">
              Fond
            </v-col>
            <v-col cols="4" md="4" sm="4">
              <div
                :style="
                  selectedColor === 'main'
                    ? 'background-color:' +
                      state.config?.lightColors.main +
                      '; ' +
                      'border: 3px solid #000; border-radius: 5px;'
                    : 'background-color:' +
                      state.config?.lightColors.main +
                      ';border: 0px;'
                "
                data-cy="categoryMainColor"
                class="drag-el"
                @click="onClickColor('main', state.config?.lightColors.main)"
              ></div>
            </v-col>
            <v-col cols="4" md="4" sm="4">
              <div
                :style="
                  selectedColor === 'onContainer'
                    ? 'background-color:' +
                      state.config?.lightColors.onContainer +
                      '; ' +
                      'border: 3px solid #000; border-radius: 5px;'
                    : 'background-color:' +
                      state.config?.lightColors.onContainer +
                      ';border: 0px;'
                "
                data-cy="categoryOnContainerColor"
                class="drag-el"
                @click="
                  onClickColor(
                    'onContainer',
                    state.config?.lightColors.onContainer
                  )
                "
              ></div>
            </v-col>
            <v-col cols="4" md="4" sm="4">
              <div
                :style="
                  selectedColor === 'container'
                    ? 'background-color:' +
                      state.config?.lightColors.container +
                      '; ' +
                      'border: 3px solid #000; border-radius: 5px;'
                    : 'background-color:' +
                      state.config?.lightColors.container +
                      ';border: 0px;'
                "
                data-cy="categoryContainerColor"
                class="drag-el"
                @click="
                  onClickColor('container', state.config?.lightColors.container)
                "
              ></div>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row no-gutters justify="center">
            <v-col cols="12" align-self="center">
              <v-color-picker
                v-model="colorPicker"
                data-cy="colorPicker"
                hide-inputs
                width="100%"
                @update:model-value="onUpdateColor"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close" variant="plain" @click="onClickClose"></v-btn>

          <v-btn
            color="primary"
            text="Save"
            variant="tonal"
            data-cy="saveCategory"
            @click="onClickSave"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { Category } from '/imports/api/interfaces/category-interface';

const props = defineProps<{
  category: Category;
  action: string;
}>();

const emit = defineEmits(['close', 'save']);

const dialog = ref(true);
const colorPicker = ref<string>('');
const selectedColor = ref<string>('container');

//Vuelidate Init
const state = reactive({
  title: '',
  calendarId: '',
  config: {
    colorName: '',
    lightColors: {
      main: '',
      container: '',
      onContainer: ''
    },
    darkColors: {
      main: '',
      onContainer: '',
      container: ''
    }
  }
});

const rules = {
  title: {
    required,
    minLength: minLength(3)
  },
  calendarId: {
    required
  }
};
const v$ = useVuelidate(rules, state);

onMounted(() => {
  colorPicker.value = props.category.config.lightColors.container;
  state.title = props.category.title;
  state.calendarId = props.category.calendarId;
  state.config = props.category.config;
});

function onClickColor(selectColor: string, color: string) {
  selectedColor.value = selectColor;
  colorPicker.value = color;
}

function onUpdateColor(color: string) {
  if (selectedColor.value === 'main') {
    state.config.lightColors.main = color;
    return;
  }

  if (selectedColor.value === 'onContainer') {
    state.config.lightColors.onContainer = color;
    return;
  }
  if (selectedColor.value === 'container') {
    state.config.lightColors.container = color;
  }
}

function onClickSave() {
  v$.value.$touch();
  if (v$.value.$invalid) return;
  emit('save', state, props.action);
}

function onClickClose() {
  emit('close');
}
</script>

<style scoped>
.sx__event {
  height: 90px;
}
.sx__time-grid-event {
  border-radius: 10px;
}
.drag-el {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}
</style>
