<template>
  <v-navigation-drawer
    id="calendarNavDrawer"
    v-model="drawer"
    :location="$vuetify.display.mobile ? 'right' : undefined"
    :max-height="500"
    :rail="$vuetify.display.mobile ? true : false"
  >
    <template #default>
      <!--  :location="$vuetify.display.mobile ? 'bottom' : undefined" -->
      <div
        v-for="(item, index) in items"
        :id="'category' + index"
        :key="item.calendarId"
        v-touch="{
          end: ($event: DragEvent) => {
            handleDrop($event, item);
            handleTouchEnd();
          },
          start: ($event: TouchEventWithOriginal) => handleTouchStart($event)
        }"
        :style="
          'background-color:' +
          item.config?.lightColors.container +
          '; color:' +
          item.config?.lightColors.onContainer
        "
        :data-cy="'category' + index"
        class="drag-el"
        :draggable="$vuetify.display.mobile ? false : true"
        @click="onClickCategory(item, 'edit')"
        @touchmove="
          startDrag($event, item);
          handleTouchMove($event);
        "
      >
        {{ $vuetify.display.mobile ? item.title.substring(0, 2) : item.title }}
      </div>
      <div
        class="plus"
        data-cy="addCategory"
        @click="onClickCategory(null, 'add')"
      >
        <strong
          ><v-icon icon="$plus" />
          {{ $vuetify.display.mobile ? '' : 'Catégorie' }}</strong
        >
      </div>
      <div class="plus" data-cy="copyEvents" @click="onClickCopy()">
        <strong
          ><v-icon icon="$copy" />
          {{ $vuetify.display.mobile ? '' : 'Événements' }}</strong
        >
      </div>

      <div class="plus top-events-title">
        {{ $vuetify.display.mobile ? 'T5' : 'Top 5 des événements' }}
      </div>
      <div
        v-for="(item, index) in topEvents"
        :id="'topEvents' + index"
        :key="index"
        v-touch="{
          end: ($event: DragEvent) => {
            handleDrop($event, item);
            handleTouchEnd();
          },
          start: ($event: TouchEventWithOriginal) => handleTouchStart($event)
        }"
        :style="
          'background-color:' +
          bgColor(item.calendarId) +
          '; color:' +
          textColor(item.calendarId)
        "
        :data-cy="'event' + index"
        class="drag-el"
        draggable="true"
        @touchmove="
          startDrag($event, item);
          handleTouchMove($event);
        "
      >
        {{ $vuetify.display.mobile ? item.title.substring(0, 2) : item.title }}
      </div>
    </template>
  </v-navigation-drawer>

  <!-- Dialog Add category-->
  <NewModifyCategory
    v-if="showNewModifyCategoryDialog"
    :category="categoryToEdit"
    :action="actionOnCategory"
    @save="onClickEditCategoryDialog"
    @close="showNewModifyCategoryDialog = false"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
import TopEvents from '/imports/api/collections/topEvents';
import { Draggable } from '@fullcalendar/interaction';
import Categories from '/imports/api/collections/categories';
import NewModifyCategory from '/imports/ui/Components/Category/NewModifyCategory.vue';
import { autorun, subscribe } from 'vue-meteor-tracker';
import {
  Category,
  PropsCalendarNavDrawer
} from '../../../../api/interfaces/category-interface';
import { updateCategory, addCategory } from '/imports/api/methods/categories';
import log from 'loglevel';
import useEmitter from '/imports/ui/EventBus';
import { useDisplay } from 'vuetify';

subscribe('topevents');

const items = autorun(() =>
  Categories.find({}, { sort: { title: 1 } }).fetch()
).result;

const topEvents = autorun(() =>
  TopEvents.find({}, { limit: 5, sort: { total: -1 } }).fetch()
).result;

const EventBus = useEmitter();
const { mobile } = useDisplay();

defineProps<PropsCalendarNavDrawer>();

let drawer = ref(true);
let showNewModifyCategoryDialog = ref(false);
let categoryToEdit = ref<Category>({
  title: '',
  calendarId: '',
  config: {
    colorName: '',
    lightColors: { main: '', container: '', onContainer: '' },
    darkColors: { main: '', container: '', onContainer: '' }
  }
});
let actionOnCategory = '';
let clone: HTMLElement | null = null;
// Your composition API code here
let draggableTopEvents: Draggable[] = [];
let draggableCategories: Draggable[] = [];
watch(topEvents, (newVal) => {
  draggableTopEvents.forEach((item) => {
    item.destroy();
  });
  newVal.forEach((item: any, index: number) => {
    if (document.getElementById('topEvents' + index) != null)
      draggableTopEvents[index] = new Draggable(
        document.getElementById('topEvents' + index)!,
        {
          itemSelector: '.drag-el',
          eventData: {
            title: item.title,
            duration: '01:00',
            extendedProps: {
              calendarId: item.calendarId,
              location: ''
            },
            sourceId: item.calendarId
          }
        }
      );
  });
  log.info('TopEvents', topEvents);
});

onMounted(() => {
  EventBus.on('eventsReady', () => {
    Meteor.setTimeout(() => {
      log.info('EventsReady');
      if (!mobile.value) {
        log.info('Not Mobile');
        destroyDraggable();
        log.info('Draggable destroyed', draggableCategories);
        items.value.forEach((item: Category, index: number) => {
          draggableCategories[index] = new Draggable(
            document.getElementById('category' + index)!,
            {
              itemSelector: '.drag-el',
              eventData: {
                id: Math.random().toString(36).substr(2, 9),
                title: item.title,
                duration: '01:00',
                extendedProps: {
                  calendarId: item.calendarId
                },
                sourceId: item.calendarId
              }
            }
          );
        });

        log.info('End Draggable', draggableCategories);
        topEvents.value.forEach((item: any, index: number) => {
          draggableTopEvents[index] = new Draggable(
            document.getElementById('topEvents' + index)!,
            {
              itemSelector: '.drag-el',
              eventData: {
                id: Math.random().toString(36).substr(2, 9),
                title: item.title,
                duration: '01:00',
                extendedProps: {
                  calendarId: item.calendarId
                },
                sourceId: item.calendarId
              }
            }
          );
        });
      }
    }, 200);
  });

  /*   new Draggable(document.getElementById('calendarNavDrawer')!, {
    itemSelector: '.drag-el'
  }); */
});

onBeforeUnmount(() => {
  EventBus.off('eventsReady');
  destroyDraggable();
});

function destroyDraggable() {
  log.info('Destroying draggable');
  draggableCategories.forEach((item) => {
    item.destroy();
  });
  draggableTopEvents.forEach((item) => {
    item.destroy();
  });
}
function bgColor(calendarId: string) {
  let item = items.value.find(
    (item: Category) => item.calendarId === calendarId
  );
  return item ? item.config.lightColors.container : '#000000';
}

function textColor(calendarId: string) {
  let item = items.value.find(
    (item: Category) => item.calendarId === calendarId
  );
  return item ? item.config.lightColors.onContainer : '#000000';
}

function onClickCategory(currentCategory: Category | null, action: string) {
  log.info('onClickCategory', currentCategory, action);
  actionOnCategory = action;
  if (currentCategory == null) {
    let randomId = Math.random().toString(36).substr(2, 9);
    categoryToEdit.value = {
      title: '',
      calendarId: randomId,
      config: {
        colorName: randomId,
        lightColors: {
          main: '#ff5733',
          container: '#d2e7ff',
          onContainer: '#660000'
        },
        darkColors: {
          main: '#ffcccb',
          onContainer: '#ff9999',
          container: '#993333'
        }
      }
    };
  } else categoryToEdit.value = currentCategory;

  showNewModifyCategoryDialog.value = !showNewModifyCategoryDialog.value;
}

function onClickEditCategoryDialog(category: Category, action: string) {
  log.info('onClickEditCategoryDialog', category, actionOnCategory);
  if (action === 'add') {
    items.value.push(category);
    addCategory.callAsync(category);
    EventBus.emit('addCategory', category);
  }
  if (action === 'edit') {
    updateCategory.callAsync(category);
    EventBus.emit('updateCategory', category);
  }
  showNewModifyCategoryDialog.value = false;
}

interface TouchEventWithOriginal extends TouchEvent {
  originalEvent: TouchEvent;
  target: HTMLElement;
}

function handleTouchStart(event: TouchEventWithOriginal) {
  let touch = event.originalEvent.touches[0];
  const original: HTMLElement = event.originalEvent.target as HTMLElement;
  clone = original.cloneNode(true) as HTMLElement;
  clone.style.position = 'absolute';
  const adjustedY = touch.clientY - 10; // Ajustez cette valeur selon vos besoins
  const adjustedX = touch.clientX + 10; // Ajustez cette valeur selon vos besoins
  clone.style.left = `${adjustedX}px`;
  clone.style.top = `${adjustedY}px`;
  clone.style.width = '100px';
  clone.style.zIndex = '2000';
  document.body.appendChild(clone);
}

function handleTouchMove(event: TouchEvent) {
  if (clone) {
    const touch = event.touches[0];
    const adjustedY = touch.clientY - 10;
    const adjustedX: number = touch.clientX + 10;
    clone.style.left = `${adjustedX}px`;
    clone.style.top = `${adjustedY}px`;
    event.preventDefault();
  }
}

function handleTouchEnd() {
  if (clone) {
    document.body.removeChild(clone);
    clone = null;
  }
}
</script>

<style scoped>
.drag-el {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  cursor: move;
}
.plus {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  cursor: pointer;
}

.top-events-title {
  margin-top: 50px; /* Ajustez cette valeur selon vos besoins */
}
</style>
