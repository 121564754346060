<template>
  <v-dialog :model-value="dialogEvent" max-width="600">
    <template #default>
      <v-card class="mx-auto">
        <v-img
          class="align-end text-white"
          gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          height="100"
          src="/books.jpg"
          cover
        >
          <v-card-title>{{ eventToEdit.title }}</v-card-title>
        </v-img>

        <v-card-subtitle class="pt-4">
          {{ toFormattedDate(eventToEdit.start) }} de
          {{ toFormattedHour(eventToEdit.start) }} à
          {{ toFormattedHour(eventToEdit.end) }}</v-card-subtitle
        >

        <v-card-text>
          <v-icon icon="$location" />
          {{ eventToEdit.location }}
          <div>
            <v-icon icon="$description" />
            {{ eventToEdit.description }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            data-cy="onEditBtn"
            text="Editer"
            color="orange"
            dialog-detail="false"
            dialog-event="true"
            @click="onClickEditEvent"
          ></v-btn>
          <v-btn
            data-cy="onDeleteBtn"
            color="orange"
            text="Supprimer"
            @click="onClickDeleteEvent(eventToEdit.id)"
          ></v-btn>
          <v-btn
            data-cy="onCloseBtn"
            color="orange"
            text="Fermer"
            @click="emit('close')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

  <GenericDialog
    v-if="showDialog"
    title="Confirmation"
    status="info"
    text="Etes-vous sûr ?"
    :options="{
      width: 'auto',
      minWidth: 300,
      minHeight: 200,
      textAlign: 'center',
      primaryButtonText: 'Oui',
      secondaryButtonText: 'Non',
      handleCancel: 'resolve'
    }"
    @on-submit="onConfirmDelete()"
    @on-cancel="
      showDialog = false;
      emit('close');
    "
  />
</template>

<script setup>
import { ref } from 'vue';
import { DateTime } from 'luxon';
import GenericDialog from '../Utils/GenericDialog.vue';

const emit = defineEmits(['deleteEvent', 'editEvent', 'close']);
const props = defineProps({
  eventToEdit: {
    type: Object,
    required: true
  }
});

let dialogEvent = ref(true);

let showDialog = ref(false);

/**
 * Handles the click event for deleting an event.
 *
 * @param {string} id - The ID of the event to be deleted.
 *
 * This function performs the following actions:
 * 1. Logs the event ID using the `log.info` method.
 * 2. Sets the `shown` state to `false`.
 * 3. Displays a confirmation dialog using `$notifier.confirm`.
 * 4. If the user confirms the action, it calls `deleteEvent.callAsync` with the event ID.
 */
function onClickDeleteEvent() {
  //  console.log('onClickDeleteEvent', essai.value.$el);
  showDialog.value = true;
}

async function onConfirmDelete() {
  showDialog.value = false;
  emit('deleteEvent', props.eventToEdit.id);
  emit('close');
}

/**
 * Emits an 'editEvent' event when the edit button is clicked.
 * This function should be used as an event handler for the edit button.
 */
function onClickEditEvent() {
  emit('editEvent');
}

/**
 * Converts a SQL date string to a formatted date string.
 *
 * @param {string} date - The date string in SQL format.
 * @returns {string} - The formatted date string in 'dd/MM/yyyy HH:mm' format.
 */
function toFormattedDate(date) {
  return DateTime.fromSQL(date).toFormat('DDDD');
}

function toFormattedHour(date) {
  return DateTime.fromSQL(date).toFormat('HH:mm');
}
</script>

<style scoped></style>
