<template>
  <v-dialog :model-value="dialogEvent" max-width="600">
    <template #default>
      <v-card
        prepend-icon="$edit"
        :title="newEvent ? 'Ajouter un document' : 'Editer un évènement'"
      >
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="state.title"
                label="Titre"
                data-cy="titleEditModifyEvent"
                density="compact"
                variant="outlined"
                :error-messages="v$.title.$errors.map((e) => e.$message)"
                @input="v$.title.$touch"
                @blur="v$.title.$touch"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field
                v-model="state.start"
                label="Date de début"
                data-cy="startDateEditModifyEvent"
                type="date"
                density="compact"
                variant="outlined"
                :error-messages="v$.start.$errors.map((e) => e.$message)"
                @input="v$.start.$touch"
                @blur="v$.start.$touch"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field
                v-model="state.startTime"
                label="Heure de début"
                data-cy="startTimeEditModifyEvent"
                type="time"
                density="compact"
                variant="outlined"
                :error-messages="v$.startTime.$errors.map((e) => e.$message)"
                @input="v$.start.$touch"
                @blur="v$.start.$touch"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6" sm="6">
              <v-text-field
                v-model="state.end"
                label="Date de fin"
                data-cy="endDateEditModifyEvent"
                density="compact"
                variant="outlined"
                type="date"
                :error-messages="v$.end.$errors.map((e) => e.$message)"
                @input="v$.end.$touch"
                @blur="v$.end.$touch"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field
                v-model="state.endTime"
                label="Heure de fin"
                data-cy="endTimeEditModifyEvent"
                type="time"
                density="compact"
                variant="outlined"
                :error-messages="v$.endTime.$errors.map((e) => e.$message)"
                @input="v$.end.$touch"
                @blur="v$.end.$touch"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="state.description"
                label="Description"
                data-cy="descriptionEditModifyEvent"
                density="compact"
                variant="outlined"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-select
                v-model="state.calendarId"
                :items="items"
                data-cy="calendarIdEditModifyEvent"
                item-value="calendarId"
                density="compact"
                variant="outlined"
                label="Catégorie"
                :error-messages="v$.calendarId.$errors.map((e) => e.$message)"
                @input="v$.calendarId.$touch"
                @blur="v$.calendarId.$touch"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-combobox
                v-model="state.location"
                v-model:search="locationSearch"
                :items="locationList"
                :hide-no-data="false"
                data-cy="locationEditModifyEvent"
                density="compact"
                variant="outlined"
                label="Emplacement"
                :error-messages="v$.location.$errors.map((e) => e.$message)"
                @input="v$.location.$touch"
                @blur="v$.location.$touch"
              >
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Pas d'emplacement "<strong>{{ locationSearch }}</strong
                      >". Appuyer sur <kbd>entrée</kbd> pour le créer.
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <small class="text-caption text-medium-emphasis"
            >*indicates required field</small
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text="Close"
            data-cy="closeBtnEditModifyEvent"
            variant="plain"
            @click="emit('close')"
          ></v-btn>

          <v-btn
            color="primary"
            data-cy="submitBtnEditModifyEvent"
            text="Save"
            variant="tonal"
            @click="onSubmitClick"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref, reactive, onBeforeMount } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useStore } from 'vuex';
import log from 'loglevel';

const store = useStore();

// Validation personnalisée pour vérifier que la date de début est inférieure à la date de fin
const isBefore = (start, startTime, end, endTime) => {
  return new Date(start + ' ' + startTime) < new Date(end + ' ' + endTime);
};

const dialogEvent = ref(true);

const props = defineProps({
  event: {
    type: Object,
    default: () => ({
      title: '',
      start: '',
      end: '',
      description: '',
      calendarId: '',
      location: ''
    })
  },
  items: {
    type: Array,
    default: () => []
  },
  newEvent: {
    type: Boolean,
    default: false
  }
});

let start = ref(props.event.start.split(' ')[0]);
let end = ref(props.event.end.split(' ')[0]);
let startTime = ref(props.event.start.split(' ')[1]);
let endTime = ref(props.event.end.split(' ')[1]);

const emit = defineEmits(['close', 'submit']);

let eventToEdit = {
  title: '',
  start: '',
  end: '',
  description: '',
  calendarId: '',
  location: ''
};

//Vuelidate Init
const state = reactive({
  title: '',
  start: '',
  end: '',
  startTime: '',
  endTime: '',
  description: '',
  calendarId: '',
  location: ''
});

const rules = {
  title: {
    required,
    minLength: minLength(3)
  },
  start: {
    required,
    isBefore: helpers.withMessage(
      ({}) => `La date de début doit être inférieure à la date de fin`,
      (value) => isBefore(value, state.startTime, state.end, state.endTime)
    )
  },
  end: {
    required,
    isBefore: helpers.withMessage(
      ({}) => `La date de fin doit être supérieure à la date de début`,
      (value) => isBefore(state.start, state.startTime, value, state.endTime)
    )
  },
  startTime: {
    required
  },
  endTime: {
    required
  },
  calendarId: {
    required
  },
  location: {}
};
const v$ = useVuelidate(rules, state);

let locationList = ref([]);
let locationSearch = ref(null);

onBeforeMount(() => {
  locationList.value = store.state.locationList;
  log.info('[EditModifyEvent][onBeforeMount] locationList', locationList.value);
});

onMounted(() => {
  state.title = props.event.title;
  state.start = start.value;
  state.end = end.value;
  state.startTime = startTime.value;
  state.endTime = endTime.value;
  state.description = props.event.description;
  state.calendarId = props.event.calendarId;
  state.location = props.event.location;
});

function onSubmitClick() {
  try {
    v$.value.$touch();
    // Vérification que le formulaire est correctement rempli
    if (v$.value.$invalid) return;

    eventToEdit.start = `${state.start} ${state.startTime}`;
    eventToEdit.end = `${state.end} ${state.endTime}`;

    /*  if (new Date(eventToEdit.start) > new Date(eventToEdit.end)) {
      alert('La date de début doit être inférieure à la date de fin');
      return;
    } */
    eventToEdit.title = state.title;
    eventToEdit.description = state.description;
    eventToEdit.calendarId = state.calendarId;

    // Because of VCombobox, location can be a string (new entry) or an object
    eventToEdit.location =
      typeof state.location === 'string'
        ? { title: state.location, value: state.location.toLowerCase() }
        : { title: state.location.title, value: state.location.value };

    eventToEdit.id = props.event.id;

    emit('submit', { eventToEdit, previousCategory: props.event.calendarId });
  } catch (error) {
    log.error('Error onSubmitClick', error);
  }
}
</script>
