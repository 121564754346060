<template>
  <VDialog
    v-model="shown"
    v-bind="options"
    :width="options.fullscreen ? '100%' : 'auto'"
  >
    <VCard v-bind="options?.contentOptions">
      <VToolbar
        v-if="title"
        :color="status === 'default' ? options.defaultColor : status"
        density="compact"
        :title="title"
      >
        <VIcon class="mr-2" :icon="options[`${status}Icon`]" />
      </VToolbar>
      <VCardText>
        {{ text }}
      </VCardText>
      <VCardItem v-if="options.showInput">
        <VTextField v-model="input" :label="options.inputOptions?.label" />
      </VCardItem>

      <VCardActions>
        <VSpacer />
        <VBtn
          v-if="!options.hideCancel"
          data-cy="GDOnCancel"
          v-bind="options?.secondaryButtonProps || options?.buttonProps"
          :text="options.secondaryButtonText"
          @click="onCancelClick"
        />
        <VBtn
          data-cy="GDOnSubmit"
          :color="status || 'primary'"
          :text="options.primaryButtonText"
          :active="true"
          @click="onSubmitClick"
        />
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
import { ref, onMounted } from 'vue';
defineOptions({ inheritAttrs: false });

//const submitButton = ref<HTMLElement | null>(null);

defineProps({
  title: {
    type: String,
    default: null
  },
  text: {
    type: String,
    default: null
  },
  options: {
    type: Object,
    default: () => ({})
  },
  status: {
    type: String,
    default: 'default'
  }
});

let shown = ref(true);
// Register a global custom directive called `v-focus` that grabs focus when inserted
/*const vFocus = {
  mounted: (el) => el.focus()
};*/

onMounted(() => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      emit('onSubmit', input.value);
    }
  });
  //  console.log('submitButton', submitButton.value);
  //Meteor.setTimeout(() => submitButton.value.focus(), 5000);
  //nextTick(() => submitButton.value.focus());
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      emit('onSubmit', input.value);
    }
  });
});

const emit = defineEmits(['onSubmit', 'onCancel']);

const input = ref('');

function onSubmitClick() {
  emit('onSubmit', input.value);
}

function onCancelClick() {
  console.log('onCancelClick');
  shown.value = false;
  emit('onCancel');
}
</script>
