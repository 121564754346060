<template>
  <v-dialog v-model="shown">
    <v-card class="mx-auto">
      <v-img
        class="align-end text-white"
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        height="100"
        src="/books.jpg"
        cover
      >
        <v-card-title>Copie d'évènements</v-card-title>
      </v-img>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card-subtitle class="pt-4">
            Sélectionnez la période à copier
          </v-card-subtitle>
          <v-date-picker
            v-model="sDate"
            data-cy="sourceDate"
            show-adjacent-months
            multiple="range"
            first-day-of-week="1"
            show-week
            hide-header
            max-width="100%"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            color="primary"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-subtitle class="pt-4"> Jour de destination </v-card-subtitle>
          <v-date-picker
            v-model="destinationDate"
            data-cy="destinationDate"
            show-adjacent-months
            first-day-of-week="1"
            show-week
            hide-header
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            color="primary"
        /></v-col>
      </v-row>

      <v-card-subtitle class="pt-4"> </v-card-subtitle>

      <v-card-text>
        <div></div>
      </v-card-text>

      <v-card-actions>
        <v-btn text="Fermer" color="orange" @click="onClickClose"></v-btn>
        <v-btn
          color="orange"
          data-cy="copyEventsButton"
          text="Copier"
          @click="onClickCopy"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { DateTime } from 'luxon';
import log from 'loglevel';
import { useNotifier } from 'vuetify-notifier';
import { copyWeek } from '/imports/api/methods/events';
import useEmitter from '../../EventBus';
const emit = defineEmits(['close']);
const props = defineProps({
  dates: {
    type: Object,
    required: true
  }
});
const $notifier = useNotifier();
const EventBus = useEmitter();
let shown = ref(true);

let sDate = ref(props.dates.datesToCopy);
let destinationDate = ref(new Date(props.dates.dateToStartCopy));
/**
 * Handles the click event to close the event details component.
 * Emits an 'update:modelValue' event with a value of false to indicate the component should be closed.
 */
function onClickClose() {
  emit('close', true);
}

function onClickCopy() {
  log.info('onClickCopy');
  emit('close', true);
  $notifier
    .confirm(
      {
        title: "Copie d'évènements",
        text:
          'Voulez-vous vraiment copier la période du ' +
          DateTime.fromJSDate(sDate.value[0]).toFormat('dd/MM/yyyy') +
          ' au ' +
          DateTime.fromJSDate(sDate.value[sDate.value.length - 1]).toFormat(
            'dd/MM/yyyy'
          ) +
          ' vers le ' +
          DateTime.fromJSDate(destinationDate.value).toFormat('DDDD') +
          ' ?'
      },
      'info',
      {
        textAlign: 'center',
        primaryButtonText: 'Oui',
        secondaryButtonText: 'Non',
        handleCancel: 'resolve'
      }
    )
    .then((result) => {
      if (result) {
        copyWeek
          .callAsync({
            date: DateTime.fromJSDate(sDate.value[0])

              .toFormat('yyyy-MM-dd'),
            nbDays: sDate.value.length,
            dateToStartCopy: DateTime.fromJSDate(
              destinationDate.value
            ).toFormat('yyyy-MM-dd')
          })
          .then(() => {
            EventBus.emit('refreshEvents');
            $notifier.alertSuccess('Événement(s) copié(s) avec succès');
          });
      }
    });
}
</script>

<style scoped></style>
