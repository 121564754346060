<template>
  <div>
    <calendar-nav-drawer
      :start-drag="startDrag"
      :handle-drop="handleDrop"
      :on-click-copy="onClickCopy"
    >
    </calendar-nav-drawer>

    <edit-modify-event
      v-if="dialogEvent"
      id="editModifyEvent"
      :event="eventToEdit"
      :items="categories"
      :new-event="newEvent"
      @close="dialogEvent = false"
      @submit="onClickEditEventDialog($event)"
    />

    <event-details
      v-if="dialogEventDetails"
      :event-to-edit="eventToEdit"
      @edit-event="
        dialogEventDetails = false;
        dialogEvent = !dialogEvent;
      "
      @close="dialogEventDetails = false"
      @delete-event="removeEvent($event)"
    />

    <copy-events-dialog
      v-if="shownCopyEventsDialog"
      data-cy="copyEventsDialog"
      :dates="DatesForCopy()"
      @close="shownCopyEventsDialog = false"
    />

    <v-dialog v-model="showDatePicker" max-width="380">
      <v-card>
        <v-card-text>
          <v-date-picker
            v-model="destinationDate"
            data-cy="destinationDate"
            show-adjacent-months
            first-day-of-week="1"
            hide-header
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            color="primary"
            @update:model-value="
              calendar.getApi().gotoDate(destinationDate);
              showDatePicker = !showDatePicker;
            " /></v-card-text></v-card
    ></v-dialog>

    <full-calendar
      ref="calendar"
      v-touch="{
        left: ($event) => swipe($event, 'left'),
        right: ($event) => swipe($event, 'right')
      }"
      :options="calendarOptions"
      ><template #eventContent="arg">
        <v-row no-gutters :style="'height: 100% ; overflow:hidden'">
          <v-col data-cy="titleEvent" cols="12">
            {{ arg.event.title }}
            <div
              v-if="store.state.settings.calendar.event.showEventHours"
              data-cy="timeEvent"
              class="text-caption text-truncate"
              :style="'font'"
            >
              {{ DateTime.fromJSDate(arg.event.start).toFormat('HH:mm') }} -
              {{ DateTime.fromJSDate(arg.event.end).toFormat('HH:mm') }}
            </div>
            <div
              v-if="arg.event.extendedProps.location"
              data-cy="locationEvent"
              class="text-caption text-truncate"
            >
              <v-chip
                prepend-icon="$location"
                density="comfortable"
                size="small"
                variant="outlined"
                :color="
                  locations.find(
                    (location) =>
                      location.value ===
                      arg.event.extendedProps.location.toLowerCase()
                  )?.bgColor || 'primary'
                "
              >
                {{ arg.event.extendedProps.location }}</v-chip
              >
            </div>
          </v-col>
        </v-row>
      </template>
    </full-calendar>

    <router-view></router-view>
  </div>
</template>

<script setup>
import fullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import frLocale from '@fullcalendar/core/locales/fr';

import CopyEventsDialog from '/imports/ui/Components/Event/CopyEventsDialog.vue';
import EventDetails from '../../Components/Event/EventDetails.vue';
import EditModifyEvent from '../../Components/Event/EditModifyEvent.vue';
import CalendarNavDrawer from './Components/CalendarNavDrawer.vue';

import {
  addEvent,
  updateEvent,
  deleteEvent
} from '/imports/api/methods/events';

import Events from '/imports/api/collections/events';
import Categories from '/imports/api/collections/categories';

import { ref, onMounted, onBeforeMount, onUnmounted, watch } from 'vue';
import log from 'loglevel';
import { DateTime } from 'luxon';
import useEmitter from '/imports/ui/EventBus';
import { useDisplay } from 'vuetify';
import { useStore } from 'vuex';

import { Tracker } from 'meteor/tracker';

//import '/imports/ui/Views/Calendar/jquery.ui.touch-punch';

const { width, mobile } = useDisplay();

const EventBus = useEmitter();
const store = useStore();

const categories = ref([]);

let mobileEventSelected = ref(false);
let locations = store.state.locationList;

let calendarOptions = {
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin],
  locales: [frLocale],
  editable: true,
  droppable: true,
  handleWindowResize: true,
  progressiveEventRendering: true,
  allDaySlot: false,
  rerenderDelay: 100,
  eventClassNames: 'my-event-class',
  eventAdd: function (info) {
    log.info('[ViewCalendar][Callback] eventAdd', info);
  },
  eventChange: function (info) {
    log.info('eventChange', info.event);
    updateEvent.callAsync({
      id: info.event.id,
      title: info.event.title,
      description: info.event.extendedProps.description,
      calendarId: info.event.extendedProps.calendarId,
      location: {
        title: info.event.extendedProps.location,
        value: info.event.extendedProps.location
      },
      start: DateTime.fromJSDate(info.event.start).toFormat('yyyy-MM-dd HH:mm'), // Utilisez la date actuelle ou une autre logique pour définir la date
      end: DateTime.fromJSDate(info.event.end).toFormat('yyyy-MM-dd HH:mm') // Utilisez la date actuelle ou une autre logique pour définir la date
    });
  },
  drop: handleDrop,
  eventDrop: eventAdded,
  eventReceive: async function (info) {
    log.info('eventReceive', info.event);
    let randomId = Math.random().toString(36).substr(2, 9);
    await addEvent.callAsync({
      id: randomId,
      title: info.event.title,
      description: '',
      location: { title: '', value: '' },
      calendarId: info.event.extendedProps.calendarId,
      start: DateTime.fromJSDate(info.event.start).toFormat('yyyy-MM-dd HH:mm'), // Utilisez la date actuelle ou une autre logique pour définir la date
      end: DateTime.fromJSDate(info.event.end).toFormat('yyyy-MM-dd HH:mm') // Utilisez la date actuelle ou une autre logique pour définir la date
    });

    log.info('events', info.event.extendedProps.calendarId, eventSources);

    eventSources[info.event.extendedProps.calendarId].remove();
    eventSources[info.event.extendedProps.calendarId] = calendar.value
      .getApi()
      .addEventSource({
        events: events.value.filter(
          (event) => event.calendarId === info.event.extendedProps.calendarId
        ),
        id: info.event.extendedProps.calendarId,
        backgroundColor: categories.value.find(
          (category) =>
            category.calendarId === info.event.extendedProps.calendarId
        ).config.lightColors.container,
        textColor: categories.value.find(
          (category) =>
            category.calendarId === info.event.extendedProps.calendarId
        ).config.lightColors.onContainer,
        borderColor: categories.value.find(
          (category) =>
            category.calendarId === info.event.extendedProps.calendarId
        ).config.lightColors.main
      });

    info.event.remove();
  },
  eventClick: function (info) {
    log.info(
      '[ViewCalendar][Callback] eventClick',
      info.event,
      DateTime.fromJSDate(info.event.start).toFormat('yyyy-MM-dd HH:mm')
    );
    if (mobile.value && mobileEventSelected.value) {
      mobileEventSelected.value = false;
      return;
    }
    eventToEdit.value = {
      id: info.event.id,
      title: info.event.title,
      description: info.event.extendedProps.description,
      calendarId: info.event.extendedProps.calendarId,
      location: info.event.extendedProps.location,
      start: DateTime.fromJSDate(info.event.start).toFormat('yyyy-MM-dd HH:mm'),
      end: DateTime.fromJSDate(info.event.end).toFormat('yyyy-MM-dd HH:mm')
    };

    dialogEventDetails.value = true;
  },
  dateClick: dateClick,
  windowResize: function (view) {
    log.info('windowResize', view);
  },
  datesSet: function (dateInfo) {
    log.info('datesSet', dateInfo);
    store.commit('setCurrentCalendarSetup', {
      start: dateInfo.start,
      viewType: dateInfo.view.type
    });
  },
  initialDate: store.state.currentCalendarSetup.start,
  eventDragStart: (e) => {
    if (mobile.value) mobileEventSelected.value = true;
    log.info('eventDragStart', e.jsEvent);
    let event;
    let randomId = Math.random().toString(36).substr(2, 9);
    const callback = (ctrlKey) => {
      if (ctrlKey) {
        event = calendar.value.getApi().addEvent(
          {
            id: randomId,
            title: e.event.title,
            extendedProps: {
              description: e.event.extendedProps.description,
              calendarId: e.event.extendedProps.calendarId,
              location: e.event.extendedProps.location
            },
            start: e.event.start,
            end: e.event.end
          },
          e.event.extendedProps.calendarId
        );
        addEvent.callAsync({
          id: randomId,
          title: e.event.title,
          description: e.event.extendedProps.description,
          calendarId: e.event.extendedProps.calendarId,
          location: {
            title: e.event.extendedProps.location,
            value: e.event.extendedProps.location
          },
          start: DateTime.fromJSDate(e.event.start).toFormat(
            'yyyy-MM-dd HH:mm'
          ), // Utilisez la date actuelle ou une autre logique pour définir la date
          end: DateTime.fromJSDate(e.event.end).toFormat('yyyy-MM-dd HH:mm') // Utilisez la date actuelle ou une autre logique pour définir la date
        });
      } else {
        event && event.remove();
        deleteEvent.callAsync({ id: randomId });
      }
    };
    callback(ctrlHeld); // Handle the case when Ctrl is alread being held
    subscribe(callback); // Handle the case when Ctrl is being held or unheld during the drag
    e.event.setExtendedProp('callback', callback); // store the callback for further unsubscribe
  },
  eventDragStop: (e) => unsubscribe(e.event.extendedProps['callback']), // stop listening when the event has been dropped

  eventResizeStart: (e) => {
    log.info('eventResizeStart', e);
    if (mobile) mobileEventSelected.value = true;
  },
  eventResizeStop: (e) => {
    log.info('eventResizeStop', e);
  },
  eventResize: (e) => {
    log.info('eventResize', e);
  },
  select: (e) => {
    log.info('select', e);
  },
  unselect: (e) => {
    log.info('unselect', e);
  },
  initialView: store.state.currentCalendarSetup.viewType,
  slotMinTime: '00:00:00',
  slotMaxTime: '24:00:00',
  nowIndicator: true,
  firstDay: 1,
  eventTimeFormat: {
    // like '14:30:00'
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  customButtons: {
    datePickerButton: {
      text: 'D',
      click: function () {
        console.log('datePickerButton clicked');
        destinationDate.value = calendar.value.getApi().getDate();
        showDatePicker.value = !showDatePicker.value;
      }
    }
  },
  buttonText: { today: 'Auj.' },

  headerToolbar: {
    left: 'prev,next today datePickerButton',
    center: 'title',
    right: 'timeGridWeek,timeGridDay,timeGridThreeDays' // user can switch between the two
  },

  slotDuration: '01:00:00',
  snapDuration: '00:15:00',
  titleFormat: { month: 'short', day: 'numeric' },
  displayEventTime: false,
  views: {
    timeGridThreeDays: {
      type: 'timeGrid',

      buttonText: '3 jours',
      duration: { days: 3 },
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        hour12: false
      }, // lower level of text
      dayHeaderFormat: {
        weekday: 'short',
        day: 'numeric',
        omitCommas: true
      }
    },
    timeGridWeek: {
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        hour12: false
      }, // lower level of text
      dayHeaderFormat: {
        weekday: 'short',
        day: 'numeric',
        omitCommas: true
      }
    },
    timeGridDay: {
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        hour12: false
      }, // lower level of text
      dayHeaderFormat: {
        weekday: 'short',
        day: 'numeric',
        omitCommas: true
      }
    }
  }
};

let calendar = ref(null);
let showDatePicker = ref(false);
let destinationDate = ref(new Date());

let events = ref([]);
// /SUBSCRIPTION CLIENT
let trackerSubEvents = undefined;
let subEvents = undefined;
const subEventsReady = ref(false);

trackerSubEvents = Tracker.autorun(() => {
  subEvents = Meteor.subscribe('events');
  subEventsReady.value = subEvents.ready();
});

// /SUBSCRIPTION CLIENT

// LIVE QUERY CLIENT
let trackerQueryEvents = undefined;

trackerQueryEvents = Tracker.autorun(() => {
  events.value = Events.find({}).fetch();
});

// /LIVE QUERY CLIENT

let trackerSubCategories = undefined;
let subCategories = undefined;
const subCategoriesReady = ref(false);

trackerSubCategories = Tracker.autorun(() => {
  subCategories = Meteor.subscribe('categories');
  subCategoriesReady.value = subCategories.ready();
});

// /SUBSCRIPTION CLIENT

// LIVE QUERY CLIENT
let trackerQueryCategories = undefined;

trackerQueryCategories = Tracker.autorun(() => {
  categories.value = Categories.find({}).fetch();
});

// /LIVE QUERY CLIENT

let eventSources = [];
watch(categories, (newVal) => {
  log.info('subCategoriesReady', newVal, events.value);

  if (newVal.length !== 0) {
    calendar.value
      .getApi()
      .getEventSources()
      .forEach((eventSource) => {
        eventSource.remove();
      });
    log.info('subCategoriesReady1', calendar.value.getApi().getEventSources());
    categories.value.forEach((category) => {
      eventSources[category.calendarId] = calendar.value
        .getApi()
        .addEventSource({
          events: events.value.filter(
            (event) => event.calendarId === category.calendarId
          ),
          id: category.calendarId,
          backgroundColor: category.config.lightColors.container,
          textColor: category.config.lightColors.onContainer,
          borderColor: category.config.lightColors.main
        });
      //   calendarsUpdatePlugin.addCalendar(category.calendarId, category.config);
    });
    log.info('eventsReadyWatch');
    EventBus.emit('eventsReady');
  }
});

const draggedItem = ref(null);

let dialogEvent = ref(false);
let dialogEventDetails = ref(false);
let shownCopyEventsDialog = ref(false);

let newEvent = ref(false);
let eventToEdit = ref({
  id: '',
  title: '',
  description: '',
  calendarId: '',
  location: '',
  start: '',
  end: ''
});

//Tests

// Beginning of the workaround for this: https://github.com/fullcalendar/fullcalendar/blob/3e89de5d8206c32b6be326133b6787d54c6fd66c/packages/interaction/src/dnd/PointerDragging.ts#L306
const ctrlKeyDescriptor = Object.getOwnPropertyDescriptor(
  MouseEvent.prototype,
  'ctrlKey'
);

// Always return false for event.ctrlKey when event is of type MouseEvent
ctrlKeyDescriptor.get = function () {
  return false;
};

Object.defineProperty(MouseEvent.prototype, 'ctrlKey', ctrlKeyDescriptor);
// End of the workaround

let ctrlHeld = false;
const [subscribe, unsubscribe] = (function () {
  let subscriptions = [];
  ['keydown', 'keyup'].forEach((x) =>
    document.addEventListener(x, (e) => {
      // emit only when the key state has changed
      if (ctrlHeld !== e.ctrlKey)
        subscriptions.forEach((fun) => fun(e.ctrlKey));

      ctrlHeld = e.ctrlKey;
    })
  );

  function subscribe(callback) {
    subscriptions.push(callback);
  }

  function unsubscribe(callback) {
    const index = subscriptions.indexOf(callback);
    subscriptions.splice(index, 1);
  }

  return [subscribe, unsubscribe];
})();

//const items = ref([]);

onBeforeMount(() => {
  // log.info('onBeforeMount');
  // Set the height of the week grid based on the window height
  //  let weekOptions = calendarControls.getWeekOptions();
  // weekOptions.gridHeight = window.innerHeight - 50 - 79 - 86; // 50: app-bar, 79: calendar-header, 86: week-header
  // calendarControls.setWeekOptions(weekOptions);
  // Set the height of the week grid based on the window height
  /**
   * Attempts to retrieve calendar settings asynchronously and set the day boundaries
   * for the calendar controls based on the retrieved settings.
   *
   * @async
   * @function
   * @throws Will log an error to the console if the getSettings method fails.
   */
  // console.log('events', events.value);
  // if (events.value.length > 0) eventsServicePlugin.set(events.value);
});

onMounted(() => {
  // Meteor.setTimeout(() => {
  log.info('onBeforeMount', categories.value);
  store.commit('setAppNavTitle', 'MonPlanningFacile by SASE');
  categories.value.forEach((category) => {
    eventSources[category.calendarId] = calendar.value.getApi().addEventSource({
      events: events.value.filter(
        (event) => event.calendarId === category.calendarId
      ),
      id: category.calendarId,
      backgroundColor: category.config.lightColors.container,
      textColor: category.config.lightColors.onContainer,
      borderColor: category.config.lightColors.main
    });
    //  calendarsUpdatePlugin.addCalendar(category.calendarId, category.config);
  });
  if (categories.value.length !== 0) {
    log.info('eventsReadyonBeforeMount');
    log.info('EventBus', EventBus);
    EventBus.emit('eventsReady', new Date());
  } // }, 100);

  //Générique settings
  calendar.value.getApi().setOption('height', window.innerHeight - 50);
  calendar.value.getApi().setOption('contentHeight', 250);
  calendar.value.getApi().setOption('expandRows', true);
  Meteor.setTimeout(() => {
    calendar.value.getApi().updateSize();
  }, 200);
  log.info(
    'onMounted',

    calendar.value.getApi().getOption('slotMinTime'),
    store.state.settings
  );

  // Paramètres utilisateur

  loadUserCalendarSettings();

  loadListener();
});

onUnmounted(() => {
  EventBus.off('clickBtnSettings');
  EventBus.off('isMenuShown');
  EventBus.off('addCategory');
  EventBus.off('updateCategory');
  EventBus.off('refreshEvents');
  EventBus.off('update:userCalendarSettings');
  removeEventListener('transitionend', () => {
    log.info('removeEventListener');
  });
  trackerSubCategories.stop();
  trackerSubEvents.stop();
  trackerQueryCategories.stop();
  trackerQueryEvents.stop();
});

function loadUserCalendarSettings() {
  calendar.value
    .getApi()
    .setOption(
      'slotMinTime',
      store.state.settings.calendar.dayBoundaries.start
    );
  calendar.value
    .getApi()
    .setOption('slotMaxTime', store.state.settings.calendar.dayBoundaries.end);

  calendar.value
    .getApi()
    .setOption(
      'displayEventTime',
      store.state.settings.calendar.event.showEventHours
    );
}

function loadListener() {
  addEventListener('transitionend', (event) => {
    if (event.propertyName === 'transform' && calendar.value !== null) {
      calendar.value.getApi().updateSize();
    }
  });

  EventBus.on('addCategory', (category) => {
    eventSources[category.calendarId] = calendar.value.getApi().addEventSource({
      events: events.value.filter(
        (event) => event.calendarId === category.calendarId
      ),
      id: category.calendarId,
      backgroundColor: category.config.lightColors.container,
      textColor: category.config.lightColors.onContainer,
      borderColor: category.config.lightColors.main
    });
    // calendarsUpdatePlugin.addCalendar(category.calendarId, category.config);
  });
  EventBus.on('updateCategory', (category) => {
    eventSources[category.calendarId].remove();
    eventSources[category.calendarId] = calendar.value.getApi().addEventSource({
      events: events.value.filter(
        (event) => event.calendarId === category.calendarId
      ),
      id: category.calendarId,
      backgroundColor: category.config.lightColors.container,
      textColor: category.config.lightColors.onContainer,
      borderColor: category.config.lightColors.main
    });

    // calendarsUpdatePlugin.updateCalendar(category.calendarId, category.config);
  });

  EventBus.on('refreshEvents', () => {
    log.info('refreshEvents', calendar.value.getApi().getEventSources());
    calendar.value
      .getApi()
      .getEventSources()
      .forEach((eventSource) => {
        eventSource.remove();
      });

    log.info('refreshEvents', calendar.value.getApi().getEventSources());
    categories.value.forEach((category) => {
      eventSources[category.calendarId] = calendar.value
        .getApi()
        .addEventSource({
          events: events.value.filter(
            (event) => event.calendarId === category.calendarId
          ),
          id: category.calendarId,
          backgroundColor: category.config.lightColors.container,
          textColor: category.config.lightColors.onContainer,
          borderColor: category.config.lightColors.main
        });
      //   calendarsUpdatePlugin.addCalendar(category.calendarId, category.config);
    });
  });

  EventBus.on('update:userCalendarSettings', () => loadUserCalendarSettings());
}
function startDrag(event, item) {
  log.info('startDrag', event, item);
  draggedItem.value = item;
  if (event instanceof MouseEvent) {
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('itemID', item.id);
  }
}

async function handleDrop(event) {
  if (!mobile.value) {
    // Prevent drop on desktop
    return;
  }
  log.info('handleDrop', event);

  let endX;
  let endY;
  if (event.touchendX) {
    endX = event.touchendX;
    endY = event.touchendY;
  } else {
    endX = event.pageX;
    endY = event.pageY;
  }

  console.log(
    'Width',
    document.getElementsByClassName('fc-timegrid-col fc-timegrid-axis')[0]
      .clientWidth
  );

  let widthTimeGridAxis = document.getElementsByClassName(
    'fc-timegrid-col fc-timegrid-axis'
  )[0].clientWidth;

  let heightOfCalendarHeader =
    document.getElementsByClassName('fc-header-toolbar')[0].clientHeight;

  let heightOfWeekHeader = document.getElementsByClassName(
    'fc-scrollgrid-section-header'
  )[0].clientHeight;

  let heightWeekGrid = document.getElementsByClassName(
    'fc-timegrid-slot-lane'
  )[0].clientHeight;

  let slotTime = Math.floor(
    (endY - 48 - heightOfCalendarHeader - heightOfWeekHeader) / heightWeekGrid
  );

  let widthDayWeekGrid = document.getElementsByClassName(
    'fc-scrollgrid-sync-inner'
  )[0].clientWidth;

  log.info('widthDayWeekGrid', widthDayWeekGrid, slotTime);
  /*
  let navDrawerElement = document.getElementById('nav-drawer');
  let menuWidth =
    navDrawerElement.style.transform != 'translateX(-256px)' ? 256 : 0;
  let categoriesDrawerWidth = mobile.value ? 0 : 256;
*/

  let startDay = calendar.value.getApi().getOption('slotMinTime');

  let slotDay = Math.floor((endX - widthTimeGridAxis) / widthDayWeekGrid);

  log.info('slotDay', slotDay);

  let date;

  date = DateTime.fromJSDate(calendar.value.getApi().view.activeStart)
    .plus({
      days: slotDay
    })
    .toFormat('yyyy-MM-dd');

  // const date = event.target.dataset.timeGridDate;

  log.info(
    'date',
    date,
    slotTime,
    slotDay,
    startDay,
    DateTime.fromSQL(startDay).toSeconds()
  );
  let startTime = DateTime.fromSeconds(
    DateTime.fromSQL(startDay).toSeconds() + slotTime * 3600
  )
    .toUTC()
    .toFormat('HH:mm');
  let endTime = DateTime.fromSeconds(
    DateTime.fromSQL(startDay).toSeconds() + (slotTime + 1) * 3600
  )
    .toUTC()
    .toFormat('HH:mm');

  const item = draggedItem.value;
  log.info('item', item, date, startTime, endTime);

  if (item) {
    // log.info('Item dropped:', item);
    // Ajoutez ici la logique pour ajouter l'événement au calendrier
    let randomId = Math.random().toString(36).substr(2, 9);
    calendar.value.getApi().addEvent(
      {
        id: randomId,
        title: item.title,
        description: '',
        calendarId: item.calendarId,
        start: date + ' ' + startTime, // Utilisez la date actuelle ou une autre logique pour définir la date
        end: date + ' ' + endTime // Utilisez la date actuelle ou une autre logique pour définir la date
      },
      eventSources[item.calendarId]
    );

    try {
      await addEvent.callAsync({
        id: randomId,
        title: item.title,
        description: '',
        calendarId: item.calendarId,
        start: date + ' ' + startTime, // Utilisez la date actuelle ou une autre logique pour définir la date
        end: date + ' ' + endTime // Utilisez la date actuelle ou une autre logique pour définir la date
      });
    } catch (err) {
      log.error(err);
    }

    draggedItem.value = null;
  }
}

async function onClickEditEventDialog(event) {
  log.info('onClickEditEventDialog', event, store.state.locationList);

  const newLocation = await store.dispatch(
    'existingLocation',
    event.eventToEdit.location
  );

  if (newLocation) {
    event.eventToEdit.location = newLocation; // L'emplacement existe déjà, on réutilise le même
  } else {
    store.commit('addLocation', event.eventToEdit.location); // L'emplacement n'existe pas, on l'ajoute
  }

  log.info(
    '[ViewCalendar][onClickEditEventDialog] Location ?',
    event.eventToEdit.location
  );
  const eventData = {
    id: event.eventToEdit.id,
    title: event.eventToEdit.title,
    description: event.eventToEdit.description,
    calendarId: event.eventToEdit.calendarId,
    location: event.eventToEdit.location,
    start: event.eventToEdit.start,
    end: event.eventToEdit.end
  };

  try {
    if (newEvent.value) {
      await addEvent.callAsync(eventData);
    } else {
      await updateEvent.callAsync(eventData);
      calendar.value.getApi().getEventById(event.eventToEdit.id).remove();
    }
  } catch (err) {
    log.error(
      `[Client] ${newEvent.value ? 'addEvent' : 'updateEvent'} method:`,
      err
    );
  }

  calendar.value.getApi().addEvent(
    {
      id: event.eventToEdit.id,
      title: event.eventToEdit.title,
      extendedProps: {
        description: event.eventToEdit.description,
        calendarId: event.eventToEdit.calendarId,
        location: event.eventToEdit.location.title
      },
      start: event.eventToEdit.start,
      end: event.eventToEdit.end
    },
    eventSources[event.eventToEdit.calendarId]
  );

  dialogEvent.value = false;
  newEvent.value = false;
  log.info(`${newEvent.value ? 'added' : 'updated'}Event`, newEvent.value);
}

function DatesForCopy() {
  console.log('calendarControls.getRange()', calendar.value.getApi().view);

  let dateToStartCopy = DateTime.fromJSDate(calendar.value.getApi().getDate());
  let datesToCopy = [];

  let nbDaysToCopy = 7;
  if (calendar.value.getApi().view.type === 'timeGridWeek') {
    nbDaysToCopy = 7;
  }
  if (calendar.value.getApi().view.type === 'timeGridDay') {
    nbDaysToCopy = 1;
  }
  if (calendar.value.getApi().view.type === 'timeGridThreeDays') {
    nbDaysToCopy = 3;
  }

  for (let i = nbDaysToCopy; i > 0; i--) {
    let date = dateToStartCopy
      .minus({
        days: i
      })
      .toISODate();
    log.info('EEEE', date);
    datesToCopy.push(new Date(date));
  }
  return {
    dateToStartCopy: dateToStartCopy.toFormat('yyyy-MM-dd'),
    datesToCopy
  };
}

function onClickCopy() {
  shownCopyEventsDialog.value = true;
}
function swipe(event, direction) {
  log.info(
    'swipe',
    direction,
    Math.abs(event.touchstartX / width.value),
    width.value
  );
  // Prevent swipe if the touch started too close to the left or right edge of the screen
  const touchStartRatio = Math.abs(event.touchstartX / width.value);
  if (
    (direction === 'right' && touchStartRatio < 0.07) ||
    (direction === 'left' && touchStartRatio > 0.93)
  ) {
    return;
  }

  /*  let nbDays = 7;
  if (calendarControls.getView() === 'day') {
    nbDays = 1;
  } */
  if (direction === 'right') {
    calendar.value.getApi().prev();
  } else if (direction === 'left') {
    calendar.value.getApi().next();
  }
}

function eventAdded(info) {
  log.info('eventAdded', info);
}

function dateClick(info) {
  log.info('dateClick', info);
  if (mobile && mobileEventSelected.value) {
    mobileEventSelected.value = false;
    return;
  }
  let start = DateTime.fromJSDate(info.date).toFormat('yyyy-MM-dd HH:mm');
  let end = DateTime.fromJSDate(info.date)
    .plus({ hours: 1 })
    .toFormat('yyyy-MM-dd HH:mm');

  eventToEdit.value = {
    id: Math.random().toString(36).substr(2, 9),
    title: '',
    description: '',
    calendarId: '',
    location: '',
    start: start,
    end: end
  };

  newEvent.value = true;
  dialogEvent.value = true;

  log.info('start', start, end);
}

async function removeEvent(id) {
  log.info('deleteEvent', id);
  calendar.value.getApi().getEventById(id).remove();
  await deleteEvent.callAsync({ id: id });
}
</script>

<style scoped>
.sx__time-grid-event {
  border-radius: 10px;
}
.drop-zone {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
}
.drag-el {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  cursor: move;
}
</style>
